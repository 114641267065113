<template>
  <div class="about">
    <h1>This is an about page</h1>

    {{ message }}

    <b-button @click="sendAuth">Test WebSocket AUTH</b-button>
    
    <br>
    <br>
    
    <b-button @click="sendTest">Test WebSocket SEND</b-button>
    <br>
    <b-button @click="sendSub">SUB</b-button><br>
    <b-button @click="sendPub('Kalle-Anka')">PUB</b-button><br>
    <b-button @click="sendPub('WERWER')">PUB (unseen)</b-button>    
    
    <b-button @click="sendHTTP('Kalle-Anka')">PUB (HTTP)</b-button>

    
  </div>
</template>

<script>
export default {
  

  computed: {

    message() {
      return this.$store.state.socket.message;
    }

  },

  methods: {

    sendTest() {
      this.$socket.send('{"some":"data'+Math.random()+'"}');
    },

    sendAuth() {
      this.$socket.send('{"auth":"token"}');
    },


    sendSub() {
      this.$socket.send('{"sub":"Kalle-Anka"}');
    },    


    sendPub( channel ) {
      this.$socket.send( JSON.stringify({pub: channel, data: { akward: channel }}) );
    },    


    sendHTTP( channel ) {
      this.$http( 'http://localhost:9000/pub', { 
        method: 'post',
        data: {
          channel: channel,
          data: {
            text: 'HTTP POSTED PLING!!!'
          }
        },
        withCredentials: true 
      }).then( ( result ) => {
        console.log(result);
      });

      // this.$socket.send( JSON.stringify({pub: channel, data: { akward: channel }}) );
    }    


  }
}
</script>
